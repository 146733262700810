import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay, Navigation } from 'swiper'
// @ts-ignore
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import ParseContent from 'components/shared/ParseContent'
import BackgroundImage from 'components/shared/BackgroundImage'
import Button from 'components/elements/Button'
import ModuleSlider from 'components/cpt/ModuleSlider'

// Images
import LaptopCase from 'img/laptop-blaud.png'
import CheckMark from 'img/checkmark.inline.svg'
import PreviousIcon from 'img/slider-previous.inline.svg'
import NextIcon from 'img/slider-next.inline.svg'

SwiperCore.use([Autoplay, Navigation])

const ModuleWrapper = styled.div`
  min-height: 100vh;
`

const BannerWrapper = styled.section`
  position: relative;
  height: 300px;

  & h1 {
    color: ${(props) => props.theme.colors.secondary};
    font-size: ${(props) => props.theme.font.size['5xl']};
  }

  & h6 {
    color: ${(props) => props.theme.colors.primary};
  }

  @media screen and (max-width: 991px) {
    height: fit-content;
  }
`

const IconWrapper = styled.div`
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(4px);
  padding: 3rem;
  border-radius: 25px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);

  img {
    width: 80px;
    height: 80px;
    object-fit: contain;
  }
`

const Laptop = styled.div`
  position: relative;
  width: 100%;
  z-index: 10;

  @media (min-width: 992px) {
    bottom: -90px;
    left: 20px;
    min-height: 270px;
  }

  @media screen and (max-width: 991px) {
    display: flex;
    justify-content: center;
    height: fit-content;

    & .swiper-container {
      height: 100%;
    }

    & > div {
      position: absolute;
      height: 295px;
      width: 500px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      top: 45px;
    }
  }

  @media screen and (max-width: 767px) {
    & > div {
      height: 195px;
      width: 330px;
      top: 40px;
    }
  }

  @media screen and (max-width: 400px) {
    & > div {
      height: 170px;
      width: 255px;
      top: 40px;
    }
  }
`

const StyledImage = styled.img`
  width: 560px;
  position: absolute;
  height: auto;

  @media screen and (max-width: 991px) {
    /* display: none; */
    position: relative;
    bottom: -30px;
    width: 610px;
  }

  @media screen and (max-width: 767px) {
    width: 400px;
    height: 100%;
  }

  @media screen and (max-width: 400px) {
    width: 310px;
    height: 200px;
    bottom: -30px;
  }
`

const Benefit = styled.div`
  & svg {
    width: 20px;
    height: 20px;
  }
`
const BenefitsWrapper = styled.div`
  @media screen and (min-width: 992px) {
    & > div:nth-child(1),
    & > div:nth-child(2) {
      margin-top: 0 !important;
    }
  }

  @media screen and (max-width: 991px) {
    & > div:nth-child(1) {
      margin-top: 0 !important;
    }
  }
`

const StyledSwiper = styled(Swiper)`
  margin-left: 51px;
  margin-right: 81px;
  padding-top: 16px;
  padding-bottom: 25px;

  iframe,
  img {
    width: 100%;
    height: 267px;
    cursor: zoom-in;
  }

  @media screen and (max-width: 991px) {
    margin: 0;
    padding: 0;

    iframe,
    img {
      height: 100%;
    }
  }
`

const PreviousButton = styled.button`
  position: absolute;
  left: 5px;
  top: 0;
  bottom: 30px;
  margin: auto;
  z-index: 2;

  &.swiper-button-disabled {
    visibility: hidden;
  }

  @media screen and (max-width: 991px) {
    left: 0px;
    bottom: -20px;

    & svg {
      height: 30px;
      width: 30px;
    }
  }
`

const NextButton = styled.button`
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 30px;
  margin: auto;
  z-index: 2;

  &.swiper-button-disabled {
    visibility: hidden;
  }

  @media screen and (max-width: 991px) {
    right: 0px;
    bottom: -20px;

    & svg {
      height: 30px;
      width: 30px;
    }
  }
`

const ModuleCrumb = styled(Link)`
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: 20px;
  font-family: ${({ theme }) => theme.font.family.secondary};

  &:hover {
    text-decoration: underline;
  }
`

const StyledBackgroundImage = styled(BackgroundImage)`
  opacity: 0.3;
`

interface PageTemplateProps {
  data: {
    page: {
      id: string
      path?: string
      title?: string
      seo: any
      // eslint-disable-next-line camelcase
      fields: GatsbyTypes.WpModule_Acffields
    }
  }
  location?: any
}

function getEmbedLink(youtubeLink: string) {
  const url = youtubeLink
  const index = url?.indexOf('=') + 1
  const videoId = url?.substring(index, url.length)
  const embed = `https://www.youtube.com/embed/${videoId}`

  return embed
}

const PageTemplate: React.FC<PageTemplateProps> = ({
  data: {
    page: { id, title, seo, fields },
  },
}) => (
  <Layout type="ecd">
    <SimpleReactLightbox>
      <SEO seo={seo} />
      <ModuleWrapper>
        <BannerWrapper>
          <StyledBackgroundImage alt="" image={fields?.background} />
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-lg-6 d-flex flex-wrap flex-lg-nowrap py-lg-0 py-3 align-items-center justify-content-lg-start justify-content-center position-relative z-index-2">
                <IconWrapper className="mb-lg-0 mb-3 d-lg-block d-none">
                  <img src={fields?.icon?.localFile?.publicURL} alt="Icoon" />
                </IconWrapper>
                <div className="pl-lg-5">
                  <div className="d-flex align-items-center">
                    <ModuleCrumb
                      to="/ecd/modules/#modules"
                      className="text-tertiary mb-1"
                    >
                      Modules
                    </ModuleCrumb>
                    <h6 className="mx-2 text-tertiary mb-1">{`>`}</h6>
                    <h6 className="text-tertiary mb-1">{title}</h6>
                  </div>
                  {/* <h6 className="text-tertiary mb-1">Modules {title}</h6> */}
                  <h1>{title}</h1>
                  <ParseContent
                    className="text-tertiary"
                    content={fields?.excerpt}
                  />
                </div>
              </div>
              {fields.laptopSlider && fields.laptopSlider.length > 0 && (
                <div className="col-lg-6">
                  <Laptop>
                    <StyledImage src={LaptopCase} alt="Image" />

                    <SRLWrapper
                      options={{
                        settings: {},
                        caption: {
                          showCaption: false,
                        },
                        buttons: {
                          showAutoplayButton: false,
                          showCloseButton: true,
                          showDownloadButton: false,
                          showFullscreenButton: false,
                        },
                        thumbnails: {},
                        progressBar: {},
                      }}
                    >
                      <StyledSwiper
                        slidesPerView={1}
                        autoplay={{ delay: 5000 }}
                        navigation={{
                          prevEl: '.swiper-button-prev',
                          nextEl: '.swiper-button-next',
                        }}
                        // loop={fields.laptopSlider.length > 1}
                      >
                        <NextButton
                          className="swiper-button-next"
                          type="button"
                        >
                          <NextIcon />
                        </NextButton>

                        <PreviousButton
                          className="swiper-button-prev"
                          type="button"
                        >
                          <PreviousIcon />
                        </PreviousButton>
                        {fields.laptopSlider.map((item, index) => (
                          <SwiperSlide key={`moduleimage${index}`}>
                            {item?.youtubeLink ? (
                              <iframe
                                src={getEmbedLink(item.youtubeLink)}
                                frameBorder={0}
                                allowFullScreen={true || undefined}
                                title="Video"
                              />
                            ) : (
                              <img
                                src={item?.image?.localFile?.publicURL}
                                loading="eager"
                                alt="Geen video foto"
                              />
                            )}
                          </SwiperSlide>
                        ))}
                      </StyledSwiper>
                    </SRLWrapper>
                  </Laptop>
                </div>
              )}
            </div>
          </div>
        </BannerWrapper>
        <section className="container pt-lg-0 pt-5">
          <div className="row">
            <div className="col-md-6 text-center flex-column d-flex justify-content-start mt-3 mb-5 my-lg-5">
              <p className="text-tertiary">
                Meer weten over de mogelijkheden van <br />
                deze module of het complete ECD?
              </p>
              <Button
                to="/ecd/contact"
                className="primary filled arrow mx-auto"
                arrow
              >
                Neem contact op
              </Button>
            </div>
          </div>
        </section>
        <section className="container mb-5">
          <div className="card border-0">
            <h2 className="text-center text-tertiary mt-n4 mb-5 pt-lg-0 pt-4">
              {title === 'Koppelingen'
                ? `De koppelingen op een rijtje`
                : `De functionaliteiten op een rijtje`}
            </h2>
            <BenefitsWrapper className="row flex-row">
              {fields.benefits?.map((benefit, i: number) => (
                <div className="col-lg-6 pb-lg-0 pb-3 col-md-4 mt-3" key={i}>
                  <Benefit className="d-flex">
                    <div className="d-flex align-items-start mr-3">
                      <CheckMark />
                    </div>
                    <div>
                      <h6 className="text-primary mb-1">{benefit?.title}</h6>
                      <ParseContent
                        className="children-mb-0"
                        content={benefit?.description}
                      />
                    </div>
                  </Benefit>
                </div>
              ))}
            </BenefitsWrapper>
          </div>
        </section>
        <section className="container d-flex justify-content-center mb-5">
          <ModuleSlider currentModuleId={id} />
        </section>
      </ModuleWrapper>
    </SimpleReactLightbox>
  </Layout>
)

export const pageQuery = graphql`
  query moduleById($id: String!) {
    page: wpModule(id: { eq: $id }) {
      id
      title
      fields: acfFields {
        background {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 2000, quality: 100)
            }
          }
        }
        laptopSlider {
          image {
            localFile {
              publicURL
            }
          }
          youtubeLink
        }
        benefits {
          description
          title
        }
        description
        excerpt
        icon {
          localFile {
            publicURL
          }
        }
        type
        fieldGroupName
      }
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublisher
        opengraphPublishedTime
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          sourceUrl
        }
        twitterImage {
          sourceUrl
        }
      }
    }
  }
`

export default PageTemplate
